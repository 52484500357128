import { Card, WithCaution, Button } from '@components'
import { PlaceholderListItem } from '@components/Placeholder'
import useApi, { wasSuccess } from '@hooks/useApi'
import {
  Avatar,
  ButtonGroup,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText
} from '@mui/material'
import { Add } from '@mui/icons-material'
import GenericPage from '@templates/Generic/Page'
import {
  NotificationSummary,
  mergeNotificationTypes
} from '@utils/notifications'
import {
  deleteAlertsEmail,
  deleteAlertsSyslog,
  EmailResponse,
  getAlertsEmail,
  getAlertsSyslog,
  SyslogResponse,
  testAlertsEmail
} from '@utils/api/alerts'
import React, { ReactElement, useState } from 'react'
import { useEffect } from 'react'
import { ApiResponse } from '@utils/api'
import useLicense from '@utils/hooks/useLicense'

interface Props {}

export default function SettingsNotificationPage({}: Props): ReactElement {
  const [loading, setLoading] = useState<boolean>(true)
  const { refreshLimits } = useLicense()

  const emailApi = useApi<EmailResponse>({
    apiMethod: getAlertsEmail
  })

  const syslogApi = useApi<SyslogResponse>({
    apiMethod: getAlertsSyslog
  })

  const deleteEmailApi = useApi<ApiResponse<string>>({
    apiMethod: deleteAlertsEmail,
    requestOnMount: false
  })

  const testEmailApi = useApi<ApiResponse<string>>({
    apiMethod: testAlertsEmail,
    requestOnMount: false
  })

  const deleteSyslogApi = useApi<ApiResponse<string>>({
    apiMethod: deleteAlertsSyslog,
    requestOnMount: false
  })

  const handleDelete = async (
    id: NotificationSummary['id'],
    type: NotificationSummary['type']
  ) => {
    if (type === 'email') {
      await deleteEmailApi.makeRequest(id)
      emailApi.makeRequest()
    }

    if (type === 'syslog') {
      await deleteSyslogApi.makeRequest(id)
      syslogApi.makeRequest()
    }

    refreshLimits()
  }

  const handleTest = async (id: NotificationSummary['id']) => {
    await testEmailApi.makeRequest(id).then((res) => {
      if (wasSuccess(res)) {
        testEmailApi.enqueueSnackbar('Test notification sent.', {
          variant: 'success'
        })
      }
    })
  }

  const items = !loading
    ? mergeNotificationTypes({
        emails: emailApi?.response?.data || [],
        syslogs: syslogApi?.response?.data || []
      })
    : []

  useEffect(() => {
    if (!emailApi.loading && !syslogApi.loading) {
      setLoading(false)
    }
  }, [emailApi.loading, syslogApi.loading])

  // Track usage
  useEffect(() => {
    refreshLimits()
  }, [])

  return (
    <GenericPage
      title="Notifications"
      breadcrumbs={[
        {
          title: 'Settings',
          to: '/settings/'
        },
        {
          title: 'Notifications'
        }
      ]}
      actions={
        <ButtonGroup
          color="primary"
          variant="outlined"
          aria-label="Host Actions"
        >
          <Button
            feature="email_notifications"
            endIcon={<Add />}
            to="/settings/notifications/add-email/"
          >
            Add Email Notification
          </Button>
          <Button
            feature="syslog_notifications"
            endIcon={<Add />}
            to="/settings/notifications/add-syslog/"
          >
            Add Syslog Notification
          </Button>
        </ButtonGroup>
      }
    >
      <Card style={{ flexGrow: 1 }}>
        <List>
          {items.map((item, index) => {
            return (
              <ListItem
                key={`${index}-${item.primaryText}`}
                divider={index + 1 < items.length}
              >
                <ListItemAvatar>
                  <Avatar>{item.icon}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={item.primaryText}
                  secondary={item.secondaryText}
                />
                <ListItemSecondaryAction>
                  <ButtonGroup
                    color="primary"
                    aria-label="outlined primary button group"
                    size="small"
                  >
                    {item.type === 'email' && (
                      <Button onClick={() => handleTest(item.id)}>Test</Button>
                    )}
                    <WithCaution>
                      <Button onClick={() => handleDelete(item.id, item.type)}>
                        Delete
                      </Button>
                    </WithCaution>
                  </ButtonGroup>
                </ListItemSecondaryAction>
              </ListItem>
            )
          })}
          {(!emailApi.response || !syslogApi.response) && (
            <PlaceholderListItem />
          )}
        </List>
      </Card>
    </GenericPage>
  )
}
