import React from 'react'
import { EmailAlerts, SyslogAlerts } from './api/alerts'
import { BsFillTerminalFill } from 'react-icons/bs'
import { ReactElement } from 'react'
import { Email } from '@mui/icons-material'

export type NotificationSummary = {
  id: string
  type: 'email' | 'syslog'
  icon: ReactElement
  primaryText: string
  secondaryText?: string
  to: string
  actions?: ReactElement
}

interface IMergeNotificationTypes {
  emails: EmailAlerts
  syslogs: SyslogAlerts
}

export function mergeNotificationTypes({
  emails,
  syslogs
}: IMergeNotificationTypes) {
  const emailSummaries: NotificationSummary[] = emails.map(
    ({ name, email_recipients }) => ({
      id: name,
      type: 'email',
      icon: <Email />,
      primaryText: name,
      secondaryText: `To: ${email_recipients.join(', ')}`,
      to: `/settings/notifications/edit-email/${name}/`
    })
  )

  const syslogSummaries: NotificationSummary[] = syslogs.map(
    ({ name, hostname, username }) => ({
      id: name,
      type: 'syslog',
      icon: <BsFillTerminalFill />,
      primaryText: name,
      secondaryText: `To: ${hostname}${username ? ' as ' + username : ''}`,
      to: `/settings/notifications/edit-syslog/${name}/`
    })
  )

  const items: NotificationSummary[] = [
    ...emailSummaries,
    ...syslogSummaries
  ].sort((a, b) => (a.primaryText > b.primaryText ? 1 : -1))

  return items
}
